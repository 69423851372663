import React from 'react';

import './index.scss';

interface SellerItemProps {
  sellerInfo: {
    avatar: string;
    name: string;
    phone: string;
    email: string;
    whatsApp: string;
    store: {
      companyName: string;
    };
  };
}

const BuyerItem: React.FC<SellerItemProps> = ({ sellerInfo }) => {
  const { avatar, name, phone, email, whatsApp, store } = sellerInfo;
  return (
    <section>
      {avatar && (
        <div>
          <strong>Foto: </strong>
          <span>{avatar}</span>
        </div>
      )}
      <div>
        <strong>Nome do vendedor: </strong>
        <span>{name}</span>
      </div>
      <div>
        <strong>E-mail: </strong>
        <span>{email ?? 'Não possui e-mail'}</span>
      </div>
      <div>
        <strong>Telefone: </strong>
        <span>{phone}</span>
      </div>
      <div>
        <strong>WhatsApp: </strong>
        <span>{whatsApp}</span>
      </div>

      {store && (
        <div>
          <strong>Nome da loja: </strong>
          <span>{store.companyName}</span>
        </div>
      )}
    </section>
  );
};

export default BuyerItem;
