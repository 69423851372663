import React, { useEffect, useState, createContext, Dispatch, SetStateAction } from 'react';
import { Redirect } from 'react-router-dom';
import firebase from 'firebase';
import app from '../../config/base';

import { AuthenticateService, GetUsersService } from '../../services/Authentication';

import { IAuthenticate, UserCredencials } from '../../interfaces/IAuthenticate';
import { UserRegisterFormData } from '../../interfaces/IUserRegister';
import { RegisteredUser } from '../../interfaces/IRegisteredUser';

import useAlert from '../../hooks/useAlert';
import useModal from '../../hooks/useModal';
import { CloseModals, OpenLoginModal } from '../../store/ui/Actions/ModalActions';

interface CurrentUser {
  name: string;
  email: string;
  phone: string;
  whatsapp: string;
  isAdmin: boolean;
}

interface InitialState {
  signed: boolean;
  signOut: Function;
  currentUser: CurrentUser | null;
  signIn: (userCredencials: UserCredencials) => Promise<any>;
  signUp: (userRegisterFormData: UserRegisterFormData) => Promise<any> | null;
}

export const AuthContext = createContext({} as InitialState);

export const AuthProvider: React.FC = ({ children }) => {
  const { dispatch } = useModal();
  const { Alert } = useAlert();
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [registeredUser, setRegisteredUser] = useState<RegisteredUser | null>(null);

  const signIn = async (credencials: UserCredencials) => {
    try {
      const { email, password } = credencials;

      const { user } = await app.auth().signInWithEmailAndPassword(email, password);

      const token = await user?.getIdToken();

      localStorage.setItem('@token', token ?? '');

      const isUserAlreadyRegistered = await GetUsersService();

      if (isUserAlreadyRegistered) {
        setCurrentUser(isUserAlreadyRegistered);

        localStorage.setItem('@current_user', JSON.stringify({ ...isUserAlreadyRegistered, token }));

        dispatch(CloseModals());

        return <Redirect to={{ pathname: '/profile' }} />;
      }

      const storagedUser = localStorage.getItem('@registered_user');

      if (!storagedUser) {
        return;
      }

      const registeredUserStoraged = JSON.parse(storagedUser);

      const userData = {
        avatar: user?.photoURL,
        name: storagedUser ? registeredUserStoraged.name : registeredUser?.name,
        phone: storagedUser ? registeredUserStoraged.phone : registeredUser?.phone,
        whatsApp: storagedUser ? registeredUserStoraged.phone : registeredUser?.phone,
        hasAcceptedTermsOfService: storagedUser ? registeredUserStoraged.acceptedTerms : registeredUser?.acceptedTerms,
        hasAcceptedPrivacyPolicy: storagedUser ? registeredUserStoraged.policy : registeredUser?.policy,
      };

      await AuthenticateService(userData);

      setCurrentUser({
        email: storagedUser ? registeredUserStoraged.email : registeredUser?.email,
        name: storagedUser ? registeredUserStoraged.name : registeredUser?.name,
        phone: storagedUser ? registeredUserStoraged.name : registeredUser?.phone,
        whatsapp: storagedUser ? registeredUserStoraged.phone : registeredUser?.phone, 
        isAdmin: isUserAlreadyRegistered.isAdmin,
      });

      localStorage.clear();
    } catch (error) {
      Alert('E-mail ou senha incorretos, tente novamente.', 'error');
    }
  };

  const signUp = async (credencials: UserRegisterFormData) => {
    try {
      const { email, password, acceptedTerms, policy, phone, name } = credencials;

      const { user } = await app.auth().createUserWithEmailAndPassword(email, password);

      if (user) {
        const token = await user.getIdToken();

        setRegisteredUser({ name, email: user?.email, acceptedTerms, phone, policy, token });

        const userToBeRegistered = { name, email: user?.email, acceptedTerms, phone, policy, token };

        localStorage.setItem('@registered_user', JSON.stringify(userToBeRegistered));

        dispatch(OpenLoginModal('modal'));

        Alert('Usuário cadastrado com sucesso.', 'success');
      }
    } catch (error) {
      Alert('Usuário já cadastrado.', 'warning');
    }
  };

  const signOut = async () => {
    await app.auth().signOut();
    setCurrentUser(null);
    localStorage.clear();
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('@current_user') || '{}');
    const token = localStorage.getItem('@token');

    if (user && token) {
      setCurrentUser(user);
    } else {
      setCurrentUser(null);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!currentUser,
        currentUser,
        signIn,
        signUp,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
