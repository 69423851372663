import { INITIAL_STATE, ActionTypes } from '../ModalProvider';

export const ModalReducer = (state = INITIAL_STATE, action: ActionTypes) => {
   switch (action.type) {
      case 'OPEN_HAS_ACCOUNT_MODAL':
         return {
            ...state,
            hasAccount: {
               isOpenModal: action.payload ? false : true,
            },
            createAccount: {
               isOpenModal: false,
            },
            loginModal: {
               isOpenModal: false,
               from: '',
            },
         };

      case 'OPEN_CREATE_ACCOUNT_MODAL':
         return {
            ...state,
            hasAccount: {
               isOpenModal: false,
            },
            createAccount: {
               isOpenModal: true,
            },
            loginModal: {
               isOpenModal: false,
               from: '',
            },
         };

      case 'OPEN_LOGIN_MODAL':
         return {
            ...state,
            hasAccount: {
               isOpenModal: false,
            },
            createAccount: {
               isOpenModal: false,
            },
            loginModal: {
               isOpenModal: true,
               from: action.payload,
            },
         };

      case 'CLOSE_MODALS':
         switch (action.payload) {
            case 'navbar':
               return {
                  ...state,
                  hasAccount: {
                     isOpenModal: false,
                  },
                  createAccount: {
                     isOpenModal: false,
                  },
                  loginModal: {
                     isOpenModal: false,
                     from: '',
                  },
               };

            case 'modal':
               return {
                  ...state,
                  hasAccount: {
                     isOpenModal: true,
                  },
                  createAccount: {
                     isOpenModal: false,
                  },
                  loginModal: {
                     isOpenModal: false,
                     from: '',
                  },
               };

            default:
               return {
                  ...state,
                  hasAccount: {
                     isOpenModal: false,
                  },
                  createAccount: {
                     isOpenModal: false,
                  },
                  loginModal: {
                     isOpenModal: false,
                     from: '',
                  },
               };
         }

      default:
         return state;
   }
};
