import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import './index.scss';

import { Divider } from 'semantic-ui-react';
import { scrollTo } from '../../utils/scroller';
import Header from '../../components/Header';
import FipeSearchForm from '../../components/FipeSearchForm';
import CardInfo from '../../components/CardInfo';

import google from '../../assets/img/google-play-badge.png';
import apple from '../../assets/img/app-store.png';

import useAuthFirebase from '../../hooks/useAuthFirebase';
import useCarInfo from '../../hooks/useCarInfo';

const Home: React.FC = () => {
  const { signed } = useAuthFirebase();
  const { searches, permitRequest } = useCarInfo();

  useEffect(() => {
    if (permitRequest) scrollTo(600);
  }, [permitRequest]);

  if (signed) {
    return <Redirect to={{ pathname: '/profile' }} />;
  }

  return (
    <div className="home__container">
      <Header />
      <main>
        <header>
          <h2>Quer saber quanto vale um veículo segundo a tabela Fipe? </h2>
          <h3>Consulte o valor forma de gratuita</h3>
        </header>

        <FipeSearchForm />
        {permitRequest && searches.length !== 0 && (
          <>
            <Divider className="divider" horizontal>
              Consultas
            </Divider>

            {searches.map((search) => (
              <CardInfo data={search} />
            ))}
          </>
        )}

        <section className="stores ui center aligned">
          <h2 style={{ textAlign: 'center' }}> Acesse nosso aplicativo </h2>
          <div>
            <img
              src={google}
              alt="app store logo"
              height="100"
              width="350"
              onClick={() => window.open('https://apps.apple.com/br/app/prakaranga/id1460901452')}
            />

            <img
              src={apple}
              alt="google play logo"
              height="100"
              width="350"
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.ominesoftware.prakaranga')}
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
