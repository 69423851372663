import React, { useEffect } from 'react';

import './index.scss';

import { Divider } from 'semantic-ui-react';
import Header from '../../components/Header';
import FipeSearchForm from '../../components/FipeSearchForm';
import CardInfo from '../../components/CardInfo';

import useAuthFirebase from '../../hooks/useAuthFirebase';
import useCarInfo from '../../hooks/useCarInfo';

import GetSearchesService from '../../services/Search/GetSearchesService';

const Profile: React.FC = () => {
  const { currentUser } = useAuthFirebase();
  const { searches, setSearches } = useCarInfo();

  useEffect(() => {
    const loadSearches = async () => {
      const response = await GetSearchesService();

      setSearches([...searches, ...response]);
    };

    loadSearches();
  }, []);

  return (
    <div className="profile__container">
      <Header />
      <main>
        <header>
          <h2> Olá! Seja Bem-vindo {currentUser?.name} </h2>
          <h3>Você acaba de entrar na melhor comunidade de venda de peças do Brasil!</h3>
        </header>

        <FipeSearchForm />

        <section className="cardinfo__section">
          <ul>
            {searches.length !== 0 && (
              <>
                <Divider className="divider" horizontal>
                  Consultas
                </Divider>
                {searches.map((searchInfo) => {
                  console.log(searchInfo);
                  return <CardInfo key={searchInfo.id} data={searchInfo} />;
                })}
              </>
            )}
          </ul>
        </section>
      </main>
    </div>
  );
};

export default Profile;
