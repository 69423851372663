import React from 'react';

import { motion, AnimatePresence, Transition, Variants } from 'framer-motion';

interface AnimatedCollapseProps {
   active: boolean;
}
// subindo tudo!!
const AnimatedCollapse: React.FC<AnimatedCollapseProps> = ({ active, children }) => {
   const variants: Variants = {
      open: { opacity: 1, height: 'auto' },
      collapsed: { opacity: 0, height: 0 },
   };

   const transitions: Transition = { duration: 0.5, ease: [0.8, 0.1, 0.01, 0.91] };

   return (
      <AnimatePresence initial={false}>
         {active && (
            <motion.section
               key="content"
               initial="collapsed"
               animate="open"
               exit="collapsed"
               variants={variants}
               transition={transitions}
            >
               {children}
            </motion.section>
         )}
      </AnimatePresence>
   );
};

export default AnimatedCollapse;
