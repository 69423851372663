import { AxiosResponse } from 'axios';
import fipeApi from '../../config/external/fipeApi';
import { CarFipeInfoResponse } from '../../interfaces/CarInfoResponse';

interface CarInfoRequestParams {
  carTypeValue: string;
  carBrandId: string;
  carModelId: string;
  carYearId: string;
}

const GetCarInfo = async (params: CarInfoRequestParams): Promise<{ data: CarFipeInfoResponse; status: number }> => {
  const response = await fipeApi.get(
    `/${params.carTypeValue}/veiculo/${params.carBrandId}/${params.carModelId}/${params.carYearId}.json`
  );

  return response;
};

export default GetCarInfo;
