import React from "react";

import { AuthProvider } from "./auth/AuthProvider";
import { UIProvider } from "./ui/UIProvider";
import { SidebarProvider } from "./ui/SidebarProvider";
import { FipeProvider } from "./fipe/FipeProvider";

const Provider: React.FC = ({ children }) => {
   return (
      <FipeProvider>
         <UIProvider>
            <AuthProvider>
               <SidebarProvider>
                  {children}
               </SidebarProvider>
            </AuthProvider>
         </UIProvider>
      </FipeProvider>
   );
};

export default Provider;
