export const GetDataSource = (orders: any) => {
  const dataSource = orders?.map((data: any) => {
    return {
      key: data.id,
      title: data.title,
      name: data.user.name,
      email: data.user.email,
      phone: data.user.phone,
      whatsApp: data.user.whatsApp,
      vehicleType: data.vehicleType.label,
      orderDate: data.createdAt,
      year: data.year,
      area: data.area,
      condition: data.condition,
      description: data.description,
      manufacturer: data.manufacturer.label,
      model: data.model.label,
      modelYears: data.model.years,
      active: data.active,
      proposals: data.proposals,
      pictures: data.pictures,
    };
  });

  return dataSource
};
