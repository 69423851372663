export const scrollTo = (to: number | undefined) => {
  window.scrollTo({
    behavior: 'smooth',
    top: to,
  });
};

export const scrollToTop = () => {
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  });
};
