import React from 'react';
import { useHistory } from 'react-router-dom';

import './index.scss';

import CustomMenu from 'react-hamburger-menu';
import { Popup } from 'semantic-ui-react';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';

import useModal from '../../hooks/useModal';
import useSidebar from '../../hooks/useSidebar';
import useAuthFirebase from '../../hooks/useAuthFirebase';
import { OpenLoginModal } from '../../store/ui/Actions/ModalActions';
import useCarInfo from '../../hooks/useCarInfo';

interface NavBarProps {
  responsive: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ responsive }) => {
  const history = useHistory();
  const { ShowSidebar, visible } = useSidebar();
  const { setSearches } = useCarInfo();
  const { dispatch } = useModal();
  const { currentUser, signOut, signed: loggedUser } = useAuthFirebase();

  const handleReport = () => {
    history.push('/report');
  };

  const logOut = async () => {
    setSearches([]);
    await signOut();
    history.push('/');
  };

  if (!responsive && !loggedUser)
    return (
      <nav className="navbar">
        <ul>
          <li onClick={() => window.open('https://prakaranga.com.br/')}>Sobre</li>
          <li onClick={() => window.open('https://prakaranga.com.br/')}>Vantagens</li>
          <li onClick={() => window.open('https://prakaranga.com.br/')}>Diferenciais</li>
          <li onClick={() => window.open('https://prakaranga.com.br/')}>Blog</li>
          <li onClick={() => window.open('https://prakaranga.com.br/')}>Contato</li>
          <li onClick={() => dispatch(OpenLoginModal('navbar'))}>Login</li>
        </ul>
      </nav>
    );

  if (responsive && loggedUser) {
    return (
      <nav className="navbar">
        <FaUserCircle size={48} color="white" className="userIcon" onClick={() => ShowSidebar()} />
      </nav>
    );
  }

  return (
    <>
      {responsive && (
        <CustomMenu
          isOpen={visible}
          menuClicked={() => ShowSidebar()}
          strokeWidth={3}
          rotate={0}
          color="white"
          borderRadius={0}
          animationDuration={0.5}
        />
      )}

      {loggedUser && (
        <nav className="navbar">
          <ul>
            <span>{currentUser?.name}</span>
            <Popup
              content={
                <ul className="popup-container">
                  {currentUser?.isAdmin && (
                    <li onClick={handleReport}>
                      <span>Relatórios</span>
                    </li>
                  )}

                  <li onClick={logOut}>
                    <span>Sair</span>
                    <FaSignOutAlt size={20} />
                  </li>
                </ul>
              }
              on="click"
              popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
              trigger={<FaUserCircle size={48} color="white" className="userIcon" />}
            />
          </ul>
        </nav>
      )}
    </>
  );
};

export default NavBar;
