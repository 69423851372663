import api from '../../config/axiosConfig';

interface ReportServiceRequestParams {
   startDate: Date;
   endDate: Date;
}

const GetReportService = async ({ startDate, endDate }: ReportServiceRequestParams) => {
   const response = await api.get('/Admin/Search', {
      params: { startDate, endDate },
   });

   return response;
};

export default GetReportService;
