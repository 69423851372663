import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Profile from '../pages/Profile';
import Report from '../pages/Report';

import PrivateRoute from '../routes/PrivateRoute';

const Routes: React.FC = () => (
   <BrowserRouter>
      <Switch>
         <Route name="Home" exact path="/" component={Home} />
         <PrivateRoute name="Profile" exact path="/profile" component={Profile} />
         <PrivateRoute name="Report" exact path="/report" component={Report} />
      </Switch>
   </BrowserRouter>
);

export default Routes;
