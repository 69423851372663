import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PrivateRouteProps } from '../interfaces/Props/PrivateRoute';
import useAuthFirebase from '../hooks/useAuthFirebase';

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...otherProps }) => {
   const { currentUser } = useAuthFirebase();

   return (
      <Route
         {...otherProps}
         render={(props) =>
            currentUser ? (
               <Component {...props} />
            ) : (
               <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
         }
      />
   );
};

export default PrivateRoute;
