import React from 'react';

import './index.scss';

interface BuyerItemProps {
  buyerinfo: {
    name: string;
    email: string;
    phone: string;
    area: number;
    whatsApp: string;
  };
}

const BuyerItem: React.FC<BuyerItemProps> = ({ buyerinfo }) => {
  return (
    <div className="expandable__buyer__content">
      <header>
        <h3>Informações do comprador</h3>
      </header>

      <section>
        <div>
          <strong>Nome: </strong>
          <span>{buyerinfo.name}</span>
        </div>
        <div>
          <strong>E-mail: </strong>
          <span>{buyerinfo.email}</span>
        </div>
        <div>
          <strong>Telefone: </strong>
          <span>{buyerinfo.phone}</span> <strong className="expandable__buyer__content__postal">Código postal: </strong>
          <span>{buyerinfo.area}</span>
        </div>
        <div>
          <strong>WhatsApp: </strong>
          <span>{buyerinfo.whatsApp}</span>
        </div>
      </section>
    </div>
  );
};

export default BuyerItem;
