import React from 'react';
import { FiInbox } from 'react-icons/fi';

const EmptyIcon: React.FC = () => {
   return (
      <>
         <FiInbox size={38} />
         <p>Não há dados, por favor preencha um período de datas acima.</p>
      </>
   );
};

export default EmptyIcon;
