import React, { useState } from 'react';

import { Accordion } from 'semantic-ui-react';
import { FiChevronDown, FiChevronUp, FiStar as AltStarIcon } from 'react-icons/fi';
import { FaRedo, FaTrashAlt, FaStar as FilledStarIcon } from 'react-icons/fa';
import Loading from 'react-loading';
import AnimatedCollapse from '../animated/AnimatedCollapse';

import useCarInfo from '../../hooks/useCarInfo';
import DeleteSearchService from '../../services/Search/DeleteSearchService';
import GetSearchesService from '../../services/Search/GetSearchesService';

import useAuthFirebase from '../../hooks/useAuthFirebase';
import useAlert from '../../hooks/useAlert';

import { scrollToTop } from '../../utils/scroller';
import SaveSearchService from '../../services/Search/SaveSearchService';

interface CarInfoResponse {
  id: string;
  anoModelo: string;
  marca: string;
  name: string;
  veiculo: string;
  preco: string;
  combustivel: string;
  referencia: string;
  fipeCodigo: string;
  newsletter: boolean;
  key: string;
  searchUrl: string;
  isSaved: boolean;
}

interface CustomAccordionProps {
  data: CarInfoResponse;
  title: string;
  id: string;
  refreshUrl: string;
  onRefreshLoading: boolean;
  onRefreshData: (id: string) => {};
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({
  children,
  title,
  id,
  data,
  onRefreshLoading,
  onRefreshData,
}) => {
  const { signed } = useAuthFirebase();
  const { Alert } = useAlert();
  const [activeIndex, ,] = useState<string | number | undefined>(0);
  const [active, setActive] = useState(true);
  const { searches, setSearches, savedSearch } = useCarInfo();

  const handleFavoriteSearch = async (id: string) => {
    const index = searches.findIndex((item) => item.id === id);
    let newArray = [...searches];

    if (newArray[index].isSaved) {
      await removeSearch(id);
    } else {
      await saveSearch();
    }

    newArray[index] = {
      ...newArray[index],
      isSaved: !newArray[index].isSaved,
    };

    setSearches(newArray);
  };

  const saveSearch = async () => {
    await SaveSearchService(data);
  };

  const removeSearch = async (carId: string) => {
    const response = await DeleteSearchService(carId);

    if (response.status !== 200) {
      Alert('Erro ao deletar consulta', 'error');
      return;
    }
  };

  const handleRemoveSearch = async (carId: string) => {
    if (signed && savedSearch) {
      await removeSearch(carId);

      setSearches(searches.filter((search) => search.id !== carId));

      scrollToTop();

      return;
    }

    setSearches(searches.filter((search) => search.id !== carId));

    scrollToTop();
  };

  return (
    <Accordion>
      <Accordion.Title active={activeIndex === 0} index={0} className="title">
        <strong>{onRefreshLoading ? 'Atualizando dados com a fipe...' : title}</strong>
        <section className="actions">
          {searches[searches.findIndex((item) => item.id === id)].isSaved ? (
            <FilledStarIcon size={26} color="#ffcc00 " onClick={() => handleFavoriteSearch(id)} />
          ) : (
            <AltStarIcon size={26} color="#ffcc00 " onClick={() => handleFavoriteSearch(id)} />
          )}
          {onRefreshLoading ? (
            <Loading width={25} height={25} type="spin" className="refreshLoadingIcon" color="#444" />
          ) : (
            <FaRedo size={20} className="refreshIcon" color="rgb(129, 129, 129)" onClick={() => onRefreshData(id)} />
          )}
          <FaTrashAlt
            size={20}
            className="trashIcon"
            color="rgb(129, 129, 129)"
            onClick={() => handleRemoveSearch(id)}
          />
          {active ? (
            <FiChevronUp size={40} className="downIcon" onClick={() => setActive(!active)} color="rgb(129, 129, 129)" />
          ) : (
            <FiChevronDown
              size={40}
              className="downIcon"
              onClick={() => setActive(!active)}
              color="rgb(129, 129, 129)"
            />
          )}
        </section>
      </Accordion.Title>

      <AnimatedCollapse active={active}>{children}</AnimatedCollapse>
    </Accordion>
  );
};

export default CustomAccordion;
