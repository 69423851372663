import { useContext } from 'react';
import { FipeContext } from '../store/fipe/FipeProvider';

const useCarInfo = () => {
   const context = useContext(FipeContext);
   const { searches, setSearches, permitRequest, setPermitRequest, savedSearch, setSavedSearch } = context;
   return {
      searches,
      setSearches,
      permitRequest,
      setPermitRequest,
      savedSearch,
      setSavedSearch,
   };
};

export default useCarInfo;
