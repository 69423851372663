import api from '../../config/axiosConfig';

interface Request {
  anoModelo: string;
  marca: string;
  name: string;
  veiculo: string;
  preco: string;
  combustivel: string;
  referencia: string;
  fipeCodigo: string;
  key: string;
  newsletter: boolean;
  searchUrl: string;
}

interface Response {
  id: string;
  anoModelo: string;
  marca: string;
  name: string;
  veiculo: string;
  preco: string;
  combustivel: string;
  referencia: string;
  fipeCodigo: string;
  key: string;
  newsletter: boolean;
  searchUrl: string;
}

const SaveSearchService = async (search: Request): Promise<Response> => {
  const response = await api.post('/FipeHistory', search);
  return response.data;
};

export default SaveSearchService;
