import XLSX from 'xlsx';

const getColumnsWidthFromWorkSheet = (WS: any) => {
  const multiplier = 1.2;
  const cols: any = {};

  Object.keys(WS).forEach((key) => {
    const col = key.replace(/[0-9]/g, '');

    if (col.substr(0, 1) !== '!') {
      const val = String(WS[key].v);
      const valMultiplied = val.length * multiplier;

      if (cols[col]) {
        cols[col] = cols[col] > valMultiplied ? cols[col] : valMultiplied;
      } else {
        cols[col] = valMultiplied;
      }
    }
  });

  const arr: any = [];
  Object.keys(cols).map((col) => arr.push({ width: cols[col] }));

  return arr;
};

const jsonToExcel = async (json: any, excelName = 'relatorio', cellFormat = false) => {
  if (!json || !json.length) {
    console.error('[jsonToExcel] Não há dados ou o formato é invalido');
    return false;
  }

  const workbook = XLSX.utils.book_new();
  let ws = await XLSX.utils.json_to_sheet(json);

  ws['!cols'] = getColumnsWidthFromWorkSheet(ws);
  XLSX.utils.book_append_sheet(workbook, ws, 'Relatório');

  return XLSX.writeFile(workbook, `${excelName}.xlsx`);
};

export { jsonToExcel };
