import React from 'react';

import { Menu } from 'semantic-ui-react';
import { FiX } from 'react-icons/fi';
import useModal from '../../hooks/useModal';
import { OpenLoginModal } from '../../store/ui/Actions/ModalActions';
import useAuthFirebase from '../../hooks/useAuthFirebase';
import useSidebar from '../../hooks/useSidebar';

interface SidebarContentProps {
   responsive: boolean;
   signed: boolean;
}

const SidebarContent: React.FC<SidebarContentProps> = ({ responsive, signed }) => {
   const { dispatch } = useModal();
   const { CloseSidebar } = useSidebar();
   const { signOut } = useAuthFirebase();

   const logOut = async () => {
      await signOut();
   };

   if (responsive && signed) {
      return (
         <>
            <Menu.Item className="sidebar__item">
               <li>
                  <a href="#" onClick={() => window.open('https://prakaranga.com.br/')}>
                     Sobre
                  </a>
                  <div className="closeModalIcon">
                     <FiX size={28} color="#f0f0f0" onClick={() => CloseSidebar()} />
                  </div>
               </li>
            </Menu.Item>

            <Menu.Item className="sidebar__item">
               <li onClick={() => window.open('https://prakaranga.com.br/')}>Diferenciais</li>
            </Menu.Item>

            <Menu.Item className="sidebar__item">
               <li onClick={() => window.open('https://prakaranga.com.br/')}>Blog</li>
            </Menu.Item>

            <Menu.Item className="sidebar__item">
               <li onClick={() => window.open('https://prakaranga.com.br/')}>Contato</li>
            </Menu.Item>

            <Menu.Item className="sidebar__item">
               <li onClick={logOut}>Sair</li>
            </Menu.Item>
         </>
      );
   }

   return (
      <>
         <Menu.Item className="sidebar__item">
            <li>
               <a href="/#" onClick={() => dispatch(OpenLoginModal('navbar'))}>
                  Login
               </a>
               <div className="closeModalIcon">
                  <FiX size={28} color="#f0f0f0" onClick={() => CloseSidebar()} />
               </div>
            </li>
         </Menu.Item>

         <Menu.Item className="sidebar__item">
            <li onClick={() => window.open('https://prakaranga.com.br/')}>Sobre</li>
         </Menu.Item>

         <Menu.Item className="sidebar__item">
            <li onClick={() => window.open('https://prakaranga.com.br/')}>Diferenciais</li>
         </Menu.Item>

         <Menu.Item className="sidebar__item">
            <li onClick={() => window.open('https://prakaranga.com.br/')}>Blog</li>
         </Menu.Item>

         <Menu.Item className="sidebar__item">
            <li onClick={() => window.open('https://prakaranga.com.br/')}>Contato</li>
         </Menu.Item>
      </>
   );
};

export default SidebarContent;
