import api from '../../config/axiosConfig';

interface Response {
  id: string;
  anoModelo: string;
  marca: string;
  name: string;
  veiculo: string;
  preco: string;
  combustivel: string;
  referencia: string;
  fipeCodigo: string;
  key: string;
  newsletter: boolean;
  searchUrl: string;
  isSaved: boolean;
}

const GetSearchesService = async (): Promise<Response[]> => {
  const response = await api.get('/FipeHistory');
  const searches = [...response.data];

  searches.forEach((item: Response) => {
    item.isSaved = true;
  });

  return searches;
};

export default GetSearchesService;
