import React from "react";

import "./style.scss";

import { MdClose } from "react-icons/md";
import { Button } from "semantic-ui-react";

import useCarInfo from "../../hooks/useCarInfo";
import useModal from "../../hooks/useModal";
import { OpenCreateAccountModal, CloseModals, OpenLoginModal } from '../../store/ui/Actions/ModalActions';

const HasAccount: React.FC = () => {
   const { dispatch } = useModal();
   const { setPermitRequest } = useCarInfo();

   const handleFipeSearch = () => {
      dispatch(CloseModals());
      setPermitRequest(true);
   }

   return (
      <div className="container">
         <h1 className="title-modal">Você já tem uma conta?</h1>
         <MdClose size={20} className='exitIcon' onClick={() => dispatch(CloseModals())} />

         <h2 className="sub-title-modal">
            Crie uma conta para salvar sua pesquisa e receber atualizaçoes da Fipe!
        </h2>
         <div className="container-buttons">
            <div className="container-button">
               <Button className=" btn__be btn__pk--primary" onClick={() => dispatch(OpenCreateAccountModal())} >
                  <p>Criar Conta</p>
               </Button>
            </div>
            <div className="container-button">
               <Button className=" btn__be btn__pk--secundary " onClick={() => dispatch(OpenLoginModal('modal'))}>
                  <p>Já tenho conta!</p>
               </Button>
            </div>
            <div className="container-button">
               <Button className=" btn__be button--style " onClick={handleFipeSearch}>
                  <p>Quero ver apenas a pesquisa!</p>
               </Button>
            </div>
         </div>
      </div>
   );
};

export default HasAccount;
