import React, { useState, useRef } from 'react';

import './index.scss';

import { Form as SemanticForm, Button as SemanticButton, Divider, Button } from 'semantic-ui-react';
import { FiX } from 'react-icons/fi';

import CustomButton from '../../components/shared/CustomButton';

import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Input } from '../../components/Form';
import { UserCredencials } from '../../interfaces/IAuthenticate';

import useAuthFirebase from '../../hooks/useAuthFirebase';
import useModal from '../../hooks/useModal';
import { CloseModals } from '../../store/ui/Actions/ModalActions';
import useCarInfo from '../../hooks/useCarInfo';

const Login: React.FC = () => {
  const { loginModal, dispatch } = useModal();
  const { signIn } = useAuthFirebase();
  const { setSearches } = useCarInfo();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const HandleSubmit: SubmitHandler<UserCredencials> = async ({ ...data }, { reset }) => {
    const { email, password } = data;

    setIsLoading(true);

    await signIn({ email, password });

    setIsLoading(false);

    reset();
  };

  return (
    <div className="login-container">
      <section className="form-container">
        <h1 className="title-login">Entrar</h1>

        <FiX size={28} className="exitIcon" onClick={() => dispatch(CloseModals(loginModal.from))} />

        <Form ref={formRef} onSubmit={HandleSubmit}>
          <SemanticForm className="form__login_semantic">
            <Input name="email" required type="email" placeholder="Digite seu e-mail" />

            <Input required name="password" type="password" placeholder="Senha" />

            {isLoading ? (
              <SemanticButton icon={{ name: 'setting' }} className="color-buttom" loading size="big" primary>
                Loading
              </SemanticButton>
            ) : (
              <SemanticButton size="big" className="color-buttom">
                Entrar
              </SemanticButton>
            )}

            <Divider horizontal>OU</Divider>
          </SemanticForm>
        </Form>
        <div className="buttom-social-media">
          <CustomButton color="facebook" name="facebook" text="Facebook" />
        </div>
      </section>
    </div>
  );
};

export default Login;
