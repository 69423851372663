import fipeApi from "../../config/external/fipeApi";

interface CarModelsRequestParams {
   carTypeValue: string;
   carBrandId: string;
}

const GetCarModels = async (params: CarModelsRequestParams) => {
   const carModels = await fipeApi.get(
      `/${params.carTypeValue}/veiculos/${params.carBrandId}.json`
   );

   return carModels.data;
};

export default GetCarModels;
