import axios from 'axios';
import dotenv from 'dotenv';
import { app } from 'firebase';

dotenv.config();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('@token');

    if (token) config.headers['Authorization'] = `Bearer ${token}`;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default api;
