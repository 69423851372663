export const isAnyEmpty = (stringsArray: Array<string>) => {
   let isEmpty = false;
   stringsArray.forEach((value) => {
      if (value === '') {
         isEmpty = true;
      }
   });

   return isEmpty;
};
