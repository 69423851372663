import React, { CSSProperties, useEffect, useState } from 'react';

import './index.scss';

import { Form } from '@unform/web';
import { Form as SemanticForm, Button as SemanticButton } from 'semantic-ui-react';
import ReactSelect from 'react-select';
import Loading from 'react-loading';
import ModalStepper from '../ModalStepper';
import { Checkbox } from '../Form';

import { OpenHasAccountModal } from '../../store/ui/Actions/ModalActions';
import { isAnyEmpty } from '../../utils/IsAnyEmpty';
import { scrollTo } from '../../utils/scroller';

import GetCarsService from '../../services/FipeService/GetCarsService';
import GetCarModelsService from '../../services/FipeService/GetCarModelsService';
import GetCarYearsService from '../../services/FipeService/GetCarYearsService';
import GetCarInfoService from '../../services/FipeService/GetCarInfoService';
import SaveSearch from '../../services/Search/SaveSearchService';

import useCarInfo from '../../hooks/useCarInfo';
import useAlert from '../../hooks/useAlert';
import useModal from '../../hooks/useModal';
import useAuthFirebase from '../../hooks/useAuthFirebase';
import useResponsive from '../../hooks/useResponsive';

import { CarFipeInfoResponse, CarInfoResponse } from '../../interfaces/CarInfoResponse';
import { GetCarInfoParams } from '../../interfaces/FipeSearchForm/GetCarInfoParams';
import { SaveSearchNotLoggedUserParams } from '../../interfaces/FipeSearchForm/SaveSearchNotLoggedUserParams';
import { SaveSearchLoggedUserParams } from '../../interfaces/FipeSearchForm/SaveSearchLoggedUserParams';
import { CarBrand, CarModel, CarYear } from '../../interfaces/FipeSearchForm/CarOption';
import { Options } from '../../interfaces/FipeSearchForm/Options';

const FipeSearchForm: React.FC = () => {
  const { dispatch } = useModal();
  const { signed } = useAuthFirebase();
  const { responsive } = useResponsive();
  const { Alert } = useAlert();
  const { searches, setSearches, setPermitRequest, savedSearch, setSavedSearch } = useCarInfo();
  const [carBrands, setCarBrands] = useState<CarBrand[]>([]);
  const [carModels, setCarModels] = useState<CarModel[]>([]);
  const [carYears, setCarYears] = useState<CarYear[]>([]);
  const [newsletter, setNewsletter] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchFormData, setSearchFormData] = useState({
    carType: {
      label: '',
      value: '',
      disabled: false,
    },
    carBrand: {
      label: '',
      value: '',
      disabled: true,
      isLoading: false,
    },
    carModel: {
      label: '',
      value: '',
      disabled: true,
      isLoading: false,
    },
    carYear: {
      label: '',
      value: '',
      disabled: true,
      isLoading: false,
    },
  });

  interface SaveSearchResponse {
    id: string;
    anoModelo: string;
    marca: string;
    name: string;
    veiculo: string;
    preco: string;
    combustivel: string;
    referencia: string;
    fipeCodigo: string;
    key: string;
    newsletter: boolean;
    searchUrl: string;
    isSaved: boolean;
  }

  const addNewSearch = (newSearch: CarInfoResponse | SaveSearchResponse) => {
    const carInfoDoesntExist = searches.every((search) => search.id !== newSearch.id);

    if (searches.length === 0) {
      setSearches([...searches, newSearch]);
    }

    if (carInfoDoesntExist) {
      setSearches([...searches, newSearch]);
    } else {
      setSearches([...searches]);
      Alert('Você já realizou essa pesquisa', 'warning');
    }

    setLoading(false);
  };

  const handleSearchFormData = (options: Options, name: any) => {
    setSearchFormData({
      ...searchFormData,
      [name]: {
        label: options.label,
        value: options.value,
      },
    });
  };

  const GetCarInfo = async ({
    carTypeValue,
    carBrandId,
    carModelId,
    carYearId,
  }: GetCarInfoParams): Promise<CarFipeInfoResponse | any> => {
    const response = await GetCarInfoService({
      carTypeValue,
      carBrandId,
      carModelId,
      carYearId,
    });

    const carInformation = response.data;

    if (response.status !== 200) {
      Alert('Nenhum dado encontrado para este veículo.', 'error');
      setLoading(false);
      return;
    }

    return carInformation;
  };

  const handleSaveSearchNotLoggedUser = async ({
    carTypeValue,
    carBrandId,
    carModelId,
    carYearId,
    searchUrl,
  }: SaveSearchNotLoggedUserParams) => {
    const response = await GetCarInfo({
      carTypeValue,
      carBrandId,
      carModelId,
      carYearId,
    });

    dispatch(OpenHasAccountModal(signed));

    addNewSearch({
      ...response,
      fipeCodigo: response.fipe_codigo,
      anoModelo: response.ano_modelo,
      newsletter,
      searchUrl,
    });

    return;
  };

  const handleSaveSearchLoggedUser = async ({
    carTypeValue,
    carBrandId,
    carModelId,
    carYearId,
    searchUrl,
  }: SaveSearchLoggedUserParams) => {
    setSearches([]);

    const response = await GetCarInfo({ carTypeValue, carBrandId, carModelId, carYearId });

    const { ano_modelo, combustivel, fipe_codigo, key, marca, name, preco, referencia, veiculo } = response;

    const data = {
      anoModelo: ano_modelo,
      marca,
      name,
      veiculo,
      preco,
      combustivel,
      referencia,
      fipeCodigo: fipe_codigo,
      key,
      newsletter,
      searchUrl,
    };

    if (!savedSearch) {
      addNewSearch({
        id: fipe_codigo,
        anoModelo: ano_modelo,
        combustivel,
        fipeCodigo: fipe_codigo,
        key: key,
        marca,
        name,
        newsletter,
        preco,
        referencia,
        veiculo,
        searchUrl,
        isSaved: false,
      });
      scrollTo(window.innerHeight * 2);
      return;
    }

    const savedSearchResponse = await SaveSearch(data);

    addNewSearch({
      ...savedSearchResponse,
      isSaved: true,
    });

    scrollTo(window.innerHeight * 2);

    return;
  };

  const handleVehicleSearch = async () => {
    const carTypeValue = searchFormData.carType.value;
    const carBrandId = searchFormData.carBrand.value;
    const carModelId = searchFormData.carModel.value;
    const carYearId = searchFormData.carYear.value;
    const searchUrl = `http://fipeapi.appspot.com/api/1/${carTypeValue}/veiculo/${carBrandId}/${carModelId}/${carYearId}.json`;

    const isAnyValueEmpty = isAnyEmpty([carTypeValue, carBrandId, carModelId, carYearId]);

    if (isAnyValueEmpty) {
      Alert(`Preencha todos os campos.`, 'warning');
      return;
    }

    setLoading(true);

    setPermitRequest(false);

    if (!signed) {
      handleSaveSearchNotLoggedUser({ carTypeValue, carBrandId, carModelId, carYearId, searchUrl });
    }

    handleSaveSearchLoggedUser({ carTypeValue, carBrandId, carModelId, carYearId, searchUrl });
  };

  const loadCarBrands = async () => {
    setSearchFormData({
      ...searchFormData,
      carBrand: {
        label: '',
        value: '',
        disabled: false,
        isLoading: true,
      },
    });

    const carTypeValue = searchFormData.carType.value;

    const response = await GetCarsService(carTypeValue);

    setCarBrands(response);

    setSearchFormData({
      ...searchFormData,
      carBrand: {
        label: '',
        value: '',
        disabled: false,
        isLoading: false,
      },
    });
  };

  const loadCarModel = async () => {
    setSearchFormData({
      ...searchFormData,
      carModel: {
        label: '',
        value: '',
        disabled: false,
        isLoading: true,
      },
    });

    const carBrandId = searchFormData.carBrand.value;
    const carTypeValue = searchFormData.carType.value;

    const response = await GetCarModelsService({
      carTypeValue,
      carBrandId,
    });

    setCarModels(response);

    setSearchFormData({
      ...searchFormData,
      carModel: {
        label: '',
        value: '',
        disabled: false,
        isLoading: false,
      },
    });
  };

  const loadCarYear = async () => {
    setSearchFormData({
      ...searchFormData,
      carYear: {
        label: '',
        value: '',
        disabled: false,
        isLoading: true,
      },
    });

    const carBrandId = searchFormData.carBrand.value;
    const carModelId = searchFormData.carModel.value;
    const carTypeValue = searchFormData.carType.value;

    const response = await GetCarYearsService({
      carTypeValue,
      carBrandId,
      carModelId,
    });

    setCarYears(response);

    setSearchFormData({
      ...searchFormData,
      carYear: {
        label: '',
        value: '',
        disabled: false,
        isLoading: false,
      },
    });
  };

  const carTypeOptions = [
    {
      label: 'Carro',
      value: 'carros',
    },
    {
      label: 'Moto',
      value: 'motos',
    },
    {
      label: 'Caminhão',
      value: 'caminhoes',
    },
  ];

  const carBrandOptions = carBrands?.map((carBrand: CarBrand) => {
    return {
      label: carBrand.name,
      value: carBrand.id,
    };
  });

  const carModelOptions = carModels?.map((carModel: CarModel) => {
    return {
      label: carModel.name,
      value: carModel.id,
    };
  });

  const carYearOptions = carYears?.map((carYear: CarYear) => {
    return {
      label: carYear.name,
      value: carYear.id,
    };
  });

  useEffect(() => {
    if (searchFormData['carType']['value'] !== '') {
      loadCarBrands();
    }
  }, [searchFormData['carType']['value']]);

  useEffect(() => {
    if (searchFormData['carBrand']['value'] !== '') {
      loadCarModel();
    }
  }, [searchFormData['carBrand']['value']]);

  useEffect(() => {
    if (searchFormData['carBrand']['value'] !== '' && searchFormData['carModel']['value'] !== '') {
      loadCarYear();
    }
  }, [searchFormData['carModel']['value']]);

  return (
    <>
      <Form className="form" onSubmit={handleVehicleSearch}>
        <SemanticForm className="form__semantic">
          <div className="form__selects__container">
            <div className="form__selects_first__row">
              <ReactSelect
                placeholder="Escolha o tipo de veículo"
                options={carTypeOptions}
                className="form__select"
                isSearchable={false}
                loadingMessage={() => 'Carregando'}
                noOptionsMessage={() => 'Nenhum resultado'}
                onChange={(option: any) => handleSearchFormData(option, 'carType')}
                styles={{
                  control: (base: CSSProperties) => ({
                    ...base,
                    height: '4.5rem',
                  }),
                }}
              />

              <ReactSelect
                placeholder={searchFormData.carBrand.isLoading ? 'Carregando...' : 'Selecione a marca'}
                options={carBrandOptions}
                className="form__select"
                isLoading={searchFormData.carBrand.isLoading}
                isDisabled={searchFormData.carBrand.disabled}
                isSearchable
                menuPlacement={responsive ? 'top' : 'bottom'}
                menuPosition={responsive ? 'fixed' : 'absolute'}
                loadingMessage={() => 'Carregando'}
                noOptionsMessage={() => 'Nenhum resultado'}
                onChange={(option: any) => handleSearchFormData(option, 'carBrand')}
                styles={{
                  control: (base: CSSProperties) => ({
                    ...base,
                    height: '4.5rem',
                  }),
                }}
              />
            </div>

            <div className="form__selects_second__row">
              <ReactSelect
                placeholder={searchFormData.carModel.isLoading ? 'Carregando...' : 'Selecione o modelo'}
                options={carModelOptions}
                className="form__select"
                isLoading={searchFormData.carModel.isLoading}
                isDisabled={searchFormData.carModel.disabled}
                isSearchable
                menuPlacement={responsive ? 'top' : 'bottom'}
                menuPosition={responsive ? 'fixed' : 'absolute'}
                loadingMessage={() => 'Carregando'}
                noOptionsMessage={() => 'Nenhum resultado'}
                onChange={(option: any) => handleSearchFormData(option, 'carModel')}
                styles={{
                  menuList: (base: CSSProperties) => ({
                    ...base,
                    height: '20rem',
                  }),
                  menuPortal: (base: CSSProperties) => ({
                    ...base,
                    height: '20rem',
                  }),
                  control: (base: CSSProperties) => ({
                    ...base,
                    height: '4.5rem',
                  }),
                }}
              />

              <ReactSelect
                placeholder="Selecione o ano"
                options={carYearOptions}
                className="form__select"
                isLoading={searchFormData.carYear.isLoading}
                isDisabled={searchFormData.carYear.disabled}
                isSearchable={!responsive}
                loadingMessage={() => 'Carregando'}
                noOptionsMessage={() => 'Nenhum resultado'}
                onChange={(option: any) => handleSearchFormData(option, 'carYear')}
                styles={{
                  menuList: (base: CSSProperties) => ({
                    ...base,
                    height: '20rem',
                  }),
                  menuPortal: (base: CSSProperties) => ({
                    ...base,
                    height: '20rem',
                  }),
                  control: (base: CSSProperties) => ({
                    ...base,
                    height: '4.5rem',
                  }),
                }}
              />
            </div>
          </div>

          <Checkbox
            name="savedSearch"
            size={40}
            checked={savedSearch}
            onChange={() => setSavedSearch(!savedSearch)}
            options={[
              {
                id: '1',
                label: 'Quero salvar pesquisa no meu perfil',
                value: 'true',
              },
            ]}
          />

          <Checkbox
            name="newsLetter"
            size={40}
            checked={newsletter}
            onChange={() => setNewsletter(!newsletter)}
            options={[
              {
                id: '2',
                label: 'Quero cadastrar e-mail e telefone para receber informaçoes de peças e serviços Prakaranga ',
                value: 'true',
              },
            ]}
          />

          <SemanticButton className="btn__pk--primary form__submit__button">
            Consultar veículo
            {loading && <Loading type="spin" width={30} height={30} />}
          </SemanticButton>
        </SemanticForm>
      </Form>

      <ModalStepper />
    </>
  );
};

export default FipeSearchForm;
