import React, { useState, createContext } from 'react';

import CustomSidebar from '../../components/CustomSidebar';

const SidebarContext = createContext<{
   ShowSidebar: Function;
   CloseSidebar: Function;
   visible: boolean;
}>({
   ShowSidebar: () => null,
   CloseSidebar: () => null,
   visible: false,
});

const SidebarProvider: React.FC = ({ children }) => {
   const [visible, setVisible] = useState(false);

   const ShowSidebar = () => {
      setVisible(true);
   };

   const CloseSidebar = () => {
      setVisible(false);
   };

   return (
      <SidebarContext.Provider
         value={{
            ShowSidebar,
            CloseSidebar,
            visible,
         }}
      >
         <CustomSidebar>{children}</CustomSidebar>
      </SidebarContext.Provider>
   );
};

export { SidebarProvider, SidebarContext };
