import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

import { CarInfoResponse } from '../../interfaces/CarInfoResponse';

interface InitialState {
   searches: CarInfoResponse[];
   setSearches: Dispatch<SetStateAction<CarInfoResponse[]>>;
   savedSearch: boolean;
   setSavedSearch: Dispatch<SetStateAction<boolean>>;
   permitRequest: boolean;
   setPermitRequest: Dispatch<SetStateAction<boolean>>;
}

export const INITIAL_STATE: InitialState = {
   searches: [],
   setSearches: () => null,
   savedSearch: true,
   setSavedSearch: () => null,
   permitRequest: false,
   setPermitRequest: () => null,
};

const FipeContext = createContext<{
   searches: CarInfoResponse[];
   setSearches: Dispatch<SetStateAction<CarInfoResponse[]>>;
   savedSearch: boolean;
   setSavedSearch: Dispatch<SetStateAction<boolean>>;
   permitRequest: boolean;
   setPermitRequest: Dispatch<SetStateAction<boolean>>;
}>(INITIAL_STATE);

const FipeProvider: React.FC = ({ children }) => {
   const [searches, setSearches] = useState<CarInfoResponse[]>([]);
   const [permitRequest, setPermitRequest] = useState(false);
   const [savedSearch, setSavedSearch] = useState(true);

   return (
      <FipeContext.Provider
         value={{
            searches,
            setSearches,
            savedSearch,
            setSavedSearch,
            permitRequest,
            setPermitRequest,
         }}
      >
         {children}
      </FipeContext.Provider>
   );
};

export { FipeProvider, FipeContext };
