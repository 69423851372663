import React, { useState } from 'react';

import './index.scss';

import 'moment/locale/pt-br';

import { CheckCircleTwoTone, MinusCircleFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { DatePicker, Button } from 'antd';

import { formatDateFromString } from '../../utils/dateFormatters';
import useAlert from '../../hooks/useAlert';

import locale from 'antd/es/date-picker/locale/pt_BR';
import Header from '../../components/Header';
import Table from '../../components/Table';
import Footer from '../../components/Table/Footer';
import ExpandableRow from '../../components/Table/ExpandableRow';
import EmptyIcon from '../../components/Table/EmptyIcon';

import GetReportService from '../../services/Report/GetReportService';
import { TranslateProposalStatus } from '../../services/Report/TranslateProposalStatus';
import { GetExcelDataSource } from '../../services/Report/GetExcelDataSource';
import { GetDataSource } from '../../services/Report/GetDataSource';

const { RangePicker } = DatePicker;

const Report: React.FC = () => {
  const { Alert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [reportDate, setReportDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleChangeReportDate = (date: any, stringDate: Array<string>) => {
    const parsedInitialDate = new Date(stringDate[0]);
    const parsedEndDate = new Date(stringDate[1]);

    setReportDate({
      startDate: parsedInitialDate,
      endDate: parsedEndDate,
    });
  };

  const handleSearchReport = async () => {
    setLoading(true);

    try {
      const response = await GetReportService({
        startDate: reportDate['startDate'],
        endDate: reportDate['endDate'],
      });

      const reports = response.data;

      if (reports.length === 0) {
        setLoading(false);
        Alert('Nenhum dado encontrado para essa consulta.', 'warning');
        return;
      }

      setOrders(response.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      Alert('⚠ Você não tem permissão para realizar consultas.', 'error');
      setLoading(false);
    }
  };

  const columns: ColumnsType = [
    {
      title: 'Produto',
      dataIndex: 'title',
      key: 'title',
      responsive: ['md'],
      width: 160,
      align: 'center',
    },

    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      responsive: ['md'],
      width: 180,
      align: 'center',
      render: (description) => (description.trim() !== '' ? description.trim() : '-'),
    },

    {
      title: 'N° de propostas',
      dataIndex: 'proposals',
      key: 'proposals',
      responsive: ['md'],
      width: 180,
      align: 'center',
      render: (proposals) => {
        if (proposals.length === 0) return 'Não há propostas';

        return `${proposals.length} proposta(s)`;
      },
    },

    {
      title: 'Tipo de veículo',
      dataIndex: 'vehicleType',
      key: 'vehicleType',
      responsive: ['md'],
      width: 100,
      align: 'center',
    },

    {
      title: 'Modelo',
      dataIndex: 'model',
      key: 'model',
      responsive: ['md'],
      width: 150,
      align: 'center',
    },

    {
      title: 'Manufaturador',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      responsive: ['md'],
      width: 100,
      align: 'center',
    },

    {
      title: 'Data do pedido',
      dataIndex: 'orderDate',
      key: 'orderDate',
      responsive: ['md'],
      width: 100,
      align: 'center',
      render: (orderDate) => formatDateFromString(orderDate),
    },

    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 100,
      render: (value) => (value ? <CheckCircleTwoTone /> : <MinusCircleFilled />),
    },
  ];

  const dataSource = GetDataSource(orders);

  return (
    <div className="report__container">
      <Header />

      <section className="report__datepicker__container">
        <h1>Relatórios</h1>
        <RangePicker
          placeholder={['Selecione uma data inicial', 'Selecione uma data final']}
          className="report__datepicker"
          locale={locale}
          onChange={handleChangeReportDate}
        />

        <Button onClick={handleSearchReport} loading={loading}>
          Consultar
        </Button>
      </section>

      <Table
        className="report__table"
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        locale={{ emptyText: <EmptyIcon /> }}
        expandable={{
          expandedRowRender: (record) => <ExpandableRow key={record.buyerId} record={record} />,
        }}
        footer={() => <Footer excelOrders={orders} />}
      />
    </div>
  );
};

export default Report;
