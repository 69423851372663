import { formatDateFromString } from '../../utils/dateFormatters';

type ConditionEnum = 1 | 2 | 3;

interface Store {
  id: string;
  companyName: string;
  tradeName: string;
  cnpj: string;
  phone: string;
  address: {
    postalCode: string;
    street: string;
    number: string;
    neighborhood: string;
    info: string;
    state: string;
    city: string;
  };
}

interface User {
  avatar: string;
  name: string;
  email: string;
  phone: string;
  whatsApp: string;
  store: Store;
}

interface Proposal {
  id: string;
  status: string;
  user: User;
  value: string;
  createdAt: string;
}

export const GetExcelDataSource = (orders: any[]) => {
  let excelData: any[] = [];

  orders?.forEach((order: any) => {
    const { proposals } = order;
    const { name: buyerName, phone: buyerPhone, email: buyerEmail } = order.user;

    const orderSource = {
      'Código(comprador)': order.id,
      'Nome(comprador)': buyerName,
      'Telefone(comprador)': buyerPhone,
      'E-mail(comprador)': buyerEmail,
      'Codigo Busca': '',
      Data: order.createdAt,
      Título: order.title,
      Descricao: order.description,
      'Tipo de veículo': order.vehicleType?.label,
      Fabricante: order.manufacturer?.label,
      Modelo: order.model?.label,
      Ano: order?.year,
      Condição: getConditionBasedOnEnum(order?.condition),
      // Fotos: parsePictures(order?.pictures),
      'Código de Área': order?.area,
      Ativo: order.active ? 'Sim' : 'Não',
    };

    if (proposals.length !== 0) {
      duplicateRow(proposals, orderSource, excelData);
    } else {
      excelData.push(orderSource);
    }
  });

  return excelData;
};

function getConditionBasedOnEnum(condition: ConditionEnum) {
  const conditionBasedOnEnum = {
    1: 'Usado / Novo',
    2: 'Usado',
    3: 'Novo',
  };

  return conditionBasedOnEnum[condition] || '';
}

function parsePictures(pictures: Array<string>) {
  const picturesParsed = JSON.stringify(pictures);

  if (picturesParsed.length === 0) {
    return 'Não há fotos';
  }

  return picturesParsed;
}

function duplicateRow(proposals: any[], orderSource: any, excelData: any[]) {
  proposals.forEach((proposal: Proposal) => {
    const proposalSource = {
      'Nome(vendedor)': proposal.user.name,
      'CNPJ(vendedor)': proposal.user.store ? proposal.user.store.cnpj : '-',
      'Cidade(vendedor)': proposal.user.store ? proposal.user.store.address.city : '-',
      'Estado(vendedor)': proposal.user.store ? proposal.user.store.address.state : '-',
      'Telefone(vendedor)': proposal.user.phone,
      'WhatsApp(vendedor)': proposal.user.whatsApp,
      'E-mail(vendedor)': proposal.user.email,
      'Código da proposta': proposal.id,
      'Valor da proposta': proposal.value,
      'Data da proposta': formatDateFromString(proposal.createdAt),
    };

    const duplicatedRow = {
      ...orderSource,
      ...proposalSource,
    };

    excelData.push(duplicatedRow);
  });
}
