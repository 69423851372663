import React from 'react';

import './index.scss';

import BuyerItem from '../BuyerItem';
import SellerItem from '../SellerItem';

import { ExpandableRowProps } from '../../../interfaces/Props/ExpandableRowProps';
import { formatDateFromString } from '../../../utils/dateFormatters';
import ProposalItem from '../ProposalItem';

const ExpandableRow: React.FC<ExpandableRowProps> = ({ record }) => {
  const buyerinfo = {
    name: record.name,
    email: record.email,
    phone: record.phone,
    area: record.area,
    whatsApp: record.whatsApp,
  };

  return (
    <div className="expandable__container">
      <div className="expandable__content">
        <BuyerItem buyerinfo={buyerinfo} />

        {record.proposals.length !== 0 && (
          <div className="expandable__seller__content">
            <header>
              <h3>Informações do vendedor</h3>
            </header>
            {record.proposals.length !== 0 &&
              record.proposals.map(({ user: seller }) => <SellerItem key={seller.id} sellerInfo={seller} />)}
          </div>
        )}

        <div className="right__container">
          {record.proposals.length !== 0 ? (
            record.proposals.map((proposal, index) => {
              const proposalinfo = {
                productTitle: record.title,
                number: index + 1,
                value: proposal.value,
                description: record.description,
                createdAt: proposal.createdAt,
                modelYears: record.modelYears,
                pictures: record.pictures,
                vehicleModel: record.model,
                vehicleType: record.vehicleType,
              };

              return <ProposalItem key={proposal.id} proposalinfo={proposalinfo} />;
            })
          ) : (
            <div className="empty-proposals-container">
              <h3>Não há propostas</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpandableRow;
