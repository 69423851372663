const errors = {
   createUser: {
      name: {
         required: "O campo nome é obrigatório.",
      },
      phone: {
         required: "O campo número de telefone é obrigatório.",
         min: "Mínimo 9 caracteres para o número de celular.",
         max: "Máximo 11 caracteres para o número de celular.",
      },
      email: {
         required: "Campo é obrigatório.",
         email: "Digite um e-mail válido.",
         regex: "",
      },
      password: {
         required: "O campo senha é obrigatório.",
         min: "Mínimo de 6 caracteres para a senha.",
         confirmPassword: 'As senhas não são iguais. Digite novamente.'
      },
   },
};

export { errors };
