import fipeApi from "../../config/external/fipeApi";

interface CarYearsRequestParams {
   carTypeValue: string;
   carModelId: string;
   carBrandId: string;
}

const GetCarYears = async (params: CarYearsRequestParams) => {
   const carYears = await fipeApi.get(
      `/${params.carTypeValue}/veiculo/${params.carBrandId}/${params.carModelId}.json`
   );

   return carYears.data;
};

export default GetCarYears;
