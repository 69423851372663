import React from 'react';

import { Button as SemanticButton, Icon } from 'semantic-ui-react';

import { ButtonProps } from '../../../interfaces/Props/ButtonProps';

const Button: React.FC<ButtonProps> = ({ color, name, text, onClick }) => {
  return (
    <SemanticButton onClick={onClick} style={{ width: '100%' }} color={color}>
      <Icon name={name} /> {text}
    </SemanticButton>
  );
};

export default Button;
