import { useContext } from 'react';
import { SidebarContext } from '../store/ui/SidebarProvider';

const useSidebar = () => {
   const context = useContext(SidebarContext);
   const { ShowSidebar, CloseSidebar, visible } = context;
   return { ShowSidebar, CloseSidebar, visible };
};

export default useSidebar;
