import React from 'react';
import { formatDateFromString } from '../../../utils/dateFormatters';

import './index.scss';

interface ProposalItemProps {
  proposalinfo: {
    productTitle: string;
    number: number;
    value: string;
    description: string;
    createdAt: string;
    vehicleType: string;
    vehicleModel: string;
    modelYears: Array<{ label: string }>;
    pictures: Array<string>;
  };
}

const ProposalItem: React.FC<ProposalItemProps> = ({ proposalinfo }) => {
  const {
    productTitle,
    number,
    value,
    description,
    createdAt,
    vehicleType,
    modelYears,
    pictures,
    vehicleModel,
  } = proposalinfo;

  return (
    <div className="expandable__proposal__content">
      <header>
        <h3>Proposta N° {number}</h3>
      </header>

      <section>
        <div>
          <strong>Produto: </strong>
          <span> {productTitle} </span>

          <strong>Preço: </strong>
          <span>{value}</span>
        </div>
        <div>
          <strong>Descrição do pedido: </strong>
          <span>{description}</span>
        </div>
        <div>
          <strong>Data da proposta: </strong>
          <span>{formatDateFromString(createdAt)}</span>
        </div>
        <div>
          <strong>Tipo de veículo: </strong>
          <span>{vehicleType}</span>
        </div>
        <div>
          <strong>Modelo do veículo: </strong>
          <span>{vehicleModel}</span>
        </div>

        <ul className="expandable__model__years">
          <strong>Anos do modelo: </strong>
          {modelYears.map(({ label }, index) => (
            <li key={index} color="blue">
              {label}
            </li>
          ))}
        </ul>

        <ul className="expandable__photos__list">
          <strong>Fotos: </strong>
          {pictures.map((picture) => (
            <img src={picture} alt="Fotos do carro da proposta" />
          ))}
        </ul>
      </section>
    </div>
  );
};

export default ProposalItem;
