import React, { memo, useState } from 'react';

import './index.scss';

import { Item } from 'semantic-ui-react';
import carro from '../../assets/img/carro.jpg';

import CustomAccordion from '../CustomAccordion';

import RefreshSearchService from '../../services/Search/RefreshSearchService';

import useCarInfo from '../../hooks/useCarInfo';

interface CardInfoProps {
  data: {
    id: string;
    anoModelo: string;
    marca: string;
    name: string;
    veiculo: string;
    preco: string;
    combustivel: string;
    referencia: string;
    fipeCodigo: string;
    newsletter: boolean;
    key: string;
    searchUrl: string;
    isSaved: boolean;
  };
}

const CardInfo: React.FC<CardInfoProps> = ({ data }) => {
  const { searches, setSearches } = useCarInfo();
  const [loading, setLoading] = useState(false);

  const refreshData = async (id: string) => {
    setLoading(true);
    const elementIndex = searches.findIndex((search) => search.id === id);
    const newSearchesArray = [...searches];

    const response = await RefreshSearchService(newSearchesArray[elementIndex].searchUrl);

    const specificElement = { ...newSearchesArray[elementIndex] };

    newSearchesArray[elementIndex] = {
      ...specificElement,
      anoModelo: response.ano_modelo,
      combustivel: response.combustivel,
      fipeCodigo: response.fipe_codigo,
      key: response.key,
      marca: response.marca,
      name: response.name,
      preco: response.preco,
      referencia: response.referencia,
      veiculo: response.veiculo,
    };

    setSearches(newSearchesArray);

    setLoading(false);
  };

  return (
    <li id="card__info" className="cardInfo__item">
      <CustomAccordion
        id={data.id}
        data={data}
        title={`${data.marca} ${data.name}`}
        refreshUrl={data.searchUrl}
        onRefreshLoading={loading}
        onRefreshData={refreshData}
      >
        <section className="cardInfo__item__accordion__content">
          <section className="cardInfo__image">
            <img src={carro} alt="car" />
          </section>

          <section className="cardInfo__data">
            <Item.Group className="cardInfo__data__group">
              <div className="cardInfo__data__item">
                <strong className="cardInfo__data__label">Mês referência:</strong>
                <span>{data.referencia}</span>
              </div>
              <div className="cardInfo__data__item">
                <strong className="cardInfo__data__label">Marca:</strong>
                <span>{data.marca}</span>
              </div>
              <div className="cardInfo__data__item">
                <strong className="cardInfo__data__label">Modelo:</strong>
                <span>{data.veiculo}</span>
              </div>
              <div className="cardInfo__data__item">
                <strong className="cardInfo__data__label">Ano Modelo:</strong>
                <span>{data.anoModelo}</span>
              </div>
            </Item.Group>
          </section>
        </section>

        <section className="cardInfo__cost">
          <strong>Preço médio</strong>
          <span>{data.preco}</span>
        </section>
      </CustomAccordion>
    </li>
  );
};

export default memo(CardInfo);
