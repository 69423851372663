import React, { useRef, useState } from 'react';

import './index.scss';

import { UserRegisterFormData } from '../../interfaces/IUserRegister';

import { Form as SemanticForm, Button as SemanticButton } from 'semantic-ui-react';
import { FiX } from 'react-icons/fi';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { Checkbox, Input, errors } from '../Form';
import * as Yup from 'yup';

import useAuthFirebase from '../../hooks/useAuthFirebase';
import useModal from '../../hooks/useModal';
import { CloseModals } from '../../store/ui/Actions/ModalActions';
import { isEqual } from '../../utils/isEqual';
import useAlert from '../../hooks/useAlert';

const CreateAccount: React.FC = () => {
  const { Alert } = useAlert();
  const { dispatch } = useModal();
  const { signUp } = useAuthFirebase();
  const [isLoading, setIsLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [policy, setPolicy] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const HandleSubmit: SubmitHandler<UserRegisterFormData> = async ({ ...data }, { reset }) => {
    try {
      setIsLoading(true);

      const password = formRef.current?.getFieldValue('password');
      const confirmPassword = formRef.current?.getFieldValue('confirmPassword');

      if (!(policy && acceptedTerms)) {
        Alert('Você precisa aceitar os termos e políticas de privacidade para prosseguir.', 'error');
        setIsLoading(false);

        return;
      }

      if (!isEqual(password, confirmPassword) && confirmPassword.trim() !== '') {
        formRef.current?.setFieldError('confirmPassword', errors['createUser']['password']['confirmPassword']);
        setIsLoading(false);
        return;
      }

      const schema = Yup.object().shape({
        name: Yup.string().required(errors['createUser']['name']['required']),
        phone: Yup.string()
          .required(errors['createUser']['phone']['required'])
          .min(11, errors['createUser']['phone']['min'])
          .max(11, errors['createUser']['phone']['max']),
        email: Yup.string()
          .required(errors['createUser']['email']['required'])
          .email(errors['createUser']['email']['email']),
        password: Yup.string()
          .required(errors['createUser']['password']['required'])
          .min(6, errors['createUser']['password']['min']),
        confirmPassword: Yup.string()
          .required(errors['createUser']['password']['required'])
          .min(6, errors['createUser']['password']['min']),
      });

      await schema.validate(data, { abortEarly: false });

      await signUp({
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        acceptedTerms: acceptedTerms,
        policy: policy,
      });

      setIsLoading(false);

      reset();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setIsLoading(false);

        const errorMessages: any = {};

        error.inner.forEach((error: any) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current?.setErrors(errorMessages);
      }
    }
  };

  return (
    <div>
      <Form ref={formRef} onSubmit={HandleSubmit}>
        <div className="container">
          <h1>Falta pouquinho!</h1>

          <FiX size={28} className="exitIcon" onClick={() => dispatch(CloseModals('modal'))} />

          <h3 className="sub-title">
            Após finalizar o cadastro sua pesquisa vai estar salva e você recebera atualizaçoes mensais. Você também
            poderá acessar nosso Marketplace de peças ;) Baixe o app!
          </h3>
        </div>

        <SemanticForm className="form__login_semantic">
          <Input name="name" type="text" placeholder="Nome" />

          <Input name="phone" type="text" placeholder="Digite seu Fone/whats" />

          <Input name="email" type="email" placeholder="Digite seu e-mail" />

          <Input name="password" type="password" placeholder="Senha" />

          <Input name="confirmPassword" type="password" placeholder="Confirme sua senha" />

          <div className="container-checkbox">
            <Checkbox
              name="acceptedTerms"
              className="checkbox--style"
              size={60}
              checked={acceptedTerms}
              onChange={() => setAcceptedTerms(!acceptedTerms)}
              options={[
                {
                  id: '1',
                  label: 'Aceito os termos e politicas',
                  value: 'true',
                },
              ]}
            />
          </div>

          <div className="container-checkbox">
            <Checkbox
              name="policy"
              className="checkbox--style"
              size={60}
              checked={policy}
              onChange={() => setPolicy(!policy)}
              options={[
                {
                  id: '2',
                  label: 'Aceito as politicas de privacidade',
                  value: 'true',
                },
              ]}
            />
          </div>

          <SemanticButton loading={isLoading} size="big" className="btn__be btn__pk--primary">
            Cadastrar
          </SemanticButton>
        </SemanticForm>
      </Form>
    </div>
  );
};

export default CreateAccount;
