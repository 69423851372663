import React from 'react';
import { Modal } from 'semantic-ui-react';
import CreateAccount from '../CreateAccount';
import HasAccount from '../HasAccount';
import Login from '../Login';

import './index.scss';

import { CloseModals } from '../../store/ui/Actions/ModalActions';
import useModal from '../../hooks/useModal';

const ModalStepper: React.FC = () => {
   const { createAccount, hasAccount, loginModal, dispatch } = useModal();

   return (
      <>
         <Modal size='tiny' onClose={() => dispatch(CloseModals())} open={hasAccount.isOpenModal}>
            <Modal.Content>
               <HasAccount />
            </Modal.Content>
         </Modal>

         <Modal size='tiny' onClose={() => dispatch(CloseModals())} open={loginModal.isOpenModal}>
            <Modal.Content>
               <Login />
            </Modal.Content>
         </Modal>


         <Modal size='tiny' onClose={() => dispatch(CloseModals())} open={createAccount.isOpenModal}>
            <Modal.Content>
               <CreateAccount />
            </Modal.Content>
         </Modal>
      </>
   );
}

export default ModalStepper;