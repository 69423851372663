import { useContext } from 'react';
import { ModalContext } from '../store/ui/ModalProvider';

const useModal = () => {
   const context = useContext(ModalContext);
   const {
      state: { loginModal, createAccount, hasAccount },
      dispatch,
   } = context;
   return {
      loginModal,
      createAccount,
      hasAccount,
      dispatch,
   };
};

export default useModal;
