import React from 'react';

import './index.scss';

import { Menu, Sidebar } from 'semantic-ui-react';

import useSidebar from '../../hooks/useSidebar';
import useAuthFirebase from '../../hooks/useAuthFirebase';

import SidebarContent from '../SidebarContent';
import useResponsive from '../../hooks/useResponsive';

const CustomSidebar: React.FC = ({ children }) => {
   const { signed } = useAuthFirebase();
   const { CloseSidebar, visible } = useSidebar();
   const { responsive } = useResponsive();

   return (
      <Sidebar.Pushable className="sidebar__container">
         <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            direction="right"
            onHide={() => CloseSidebar()}
            vertical
            visible={visible}
            width={window.innerWidth < 368 ? 'thin' : 'wide'}
         >
            <SidebarContent responsive={responsive} signed={signed} />
         </Sidebar>

         <Sidebar.Pusher>{children}</Sidebar.Pusher>
      </Sidebar.Pushable>
   );
};

export default CustomSidebar;
