import React, { useEffect, useState, createContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalProvider } from "./ModalProvider";

type TypeOptions = "info" | "success" | "warning" | "error" | "default" | "dark" | undefined;

const UIContext = createContext<{
   responsive: boolean;
   alert: (message: string, type: TypeOptions) => any
}>({
   responsive: false,
   alert: () => null
});

const UIProvider: React.FC = ({ children }) => {
   const [responsive, setResponsive] = useState(false);

   const alert = (message: string, type: TypeOptions) => toast(message, {
      type
   });

   useEffect(() => {
      if (window.innerWidth < 1000) {
         console.log(window.innerHeight, window.innerWidth);
         setResponsive(true);
         console.log(responsive)
      }
   }, [responsive]);

   return (
      <UIContext.Provider value={{ responsive, alert }}>
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
         />
         <ModalProvider>
            {children}
         </ModalProvider>
      </UIContext.Provider>
   );
};

export { UIProvider, UIContext };
