import React from 'react';

import logo from '../../assets/img/logo.png';

import './index.scss';

import useResponsive from '../../hooks/useResponsive';
import NavBar from '../NavBar';

const Header: React.FC = () => {
   const { responsive } = useResponsive();

   return (
      <header className="header__container">
         <section className="header__logo">
            <img src={logo} alt="brandmark" onClick={() => window.open('https://prakaranga.com.br/')} />
         </section>
         <NavBar responsive={responsive} />
      </header>
   );
};

export default Header;
