import { OpenLoginModalActionType } from "./Types";

export const OpenHasAccountModal = (payload = false) => {
   return {
      type: "OPEN_HAS_ACCOUNT_MODAL",
      payload,
   };
};

export const OpenCreateAccountModal = () => {
   return {
      type: "OPEN_CREATE_ACCOUNT_MODAL",
   };
};

export const OpenLoginModal = (payload: OpenLoginModalActionType = "") => {
   return {
      type: "OPEN_LOGIN_MODAL",
      payload,
   };
};

export const CloseModals = (payload = "") => {
   return {
      type: "CLOSE_MODALS",
      payload,
   };
};
