import axios from "axios";

const fipeApi = axios.create({
   baseURL: "http://fipeapi.appspot.com/api/1",
});

axios.interceptors.request.use((request) => {
   request.headers["Access-Control-Allow-Origin"] = "*";

   return request;
});

export default fipeApi;
