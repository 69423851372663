import React, { createContext, useReducer, Dispatch } from "react";

import { ModalReducer } from './Reducers/ModalReducer';

interface InitialState {
   hasAccount: {
      isOpenModal: boolean;
   },
   loginModal: {
      isOpenModal: boolean;
      from: string;
   },
   createAccount: {
      isOpenModal: boolean;
   }
}

export type ActionTypes = {
   type:
   | 'OPEN_HAS_ACCOUNT_MODAL'
   | 'OPEN_CREATE_ACCOUNT_MODAL'
   | 'OPEN_LOGIN_MODAL'
   | 'CLOSE_MODALS',

   payload: any
}

export const INITIAL_STATE: InitialState = {
   hasAccount: {
      isOpenModal: false,
   },
   createAccount: {
      isOpenModal: false,
   },
   loginModal: {
      isOpenModal: false,
      from: ''
   }
}

const ModalContext = createContext<{
   state: InitialState,
   dispatch: Dispatch<any>
}>({
   state: INITIAL_STATE,
   dispatch: () => null
});

const ModalProvider: React.FC = ({ children }) => {
   const [state, dispatch] = useReducer(ModalReducer, INITIAL_STATE);

   return (
      <ModalContext.Provider value={{ state, dispatch }}>
         {children}
      </ModalContext.Provider>
   );
};

export { ModalProvider, ModalContext };
