import React, { useState } from 'react';

import { Button } from 'antd';
import { jsonToExcel } from '../../../utils/excel/exportToExcel';
import { GetExcelDataSource } from '../../../services/Report/GetExcelDataSource';

interface FooterProps {
  excelOrders: any[];
}

const Footer: React.FC<FooterProps> = ({ excelOrders }) => {
  const [loading, setLoading] = useState(false);

  async function handleDownloadExcel() {
    setLoading(true);

    const excelDataSource = GetExcelDataSource(excelOrders);

    await jsonToExcel(excelDataSource);

    setLoading(false);
  }

  return (
    <Button loading={loading} onClick={handleDownloadExcel}>
      Donwload relatório
    </Button>
  );
};

export default Footer;
