import React from 'react';

import './styles/global.scss';
import 'antd/dist/antd.css';

import Provider from './store';

import Routes from './routes/routes';

const App: React.FC = () => (
   <Provider>
      <Routes />
   </Provider>
);

export default App;
